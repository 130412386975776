import React, {useState} from 'react'
import Layout from '../components/layout'
import '../vars.css'
import Card from '../components/card'
import usePosts from '../hooks/use-posts'
import PostPreview from '../components/post-preview'
import {css} from '@emotion/react'
import {Link} from 'gatsby'

export default () => {
  const [toggle, setToggle] = useState(false)
  const posts = usePosts()

  return (
    <Layout>
      <Card>
        <h1>
          Hi, I am Kamal{' '}
          <span role="img" aria-label="wave">
            👋
          </span>
        </h1>
        <p>
          Welcome to my <Link to="/digital-garden">digital garden</Link>; this is my attempt to share what I am learning.
        </p>
      </Card>
      <h2>Posts</h2>
      {posts.map(post => (
        <PostPreview key={post.slug} post={post} />
      ))}
    </Layout>
  )
}
