import React from 'react'
import {css} from '@emotion/react'
import {Link} from 'gatsby'

const PostPreview = ({post}) => (
  <article
    css={css`
      display: flex;
      align-items: center;
      align-content: center;
    `}
  >
    <p
      css={css`
        margin-top: 1rem;
        margin-right: 1rem;
        opacity: 0.5;
      `}
    >
      {post.date}
    </p>
    <h3>
      <Link
        to={post.slug}
        css={css`
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        `}
      >
        {post.title}
      </Link>
    </h3>
  </article>
)

export default PostPreview
