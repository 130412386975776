import {graphql, useStaticQuery} from 'gatsby'

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {frontmatter: {published: {eq: true}}}
        sort: {fields: frontmatter___date, order: DESC}
      ) {
        nodes {
          frontmatter {
            date(formatString: "MMM DD")
            title
            slug
          }
        }
      }
    }
  `)

  return data.allMdx.nodes.map((post) => ({
    title: post.frontmatter.title,
    slug: post.frontmatter.slug,
    date: post.frontmatter.date,
  }))
}

export default usePosts
